import React from "react"
import { Waypoint } from 'react-waypoint';
import { Link } from 'gatsby'

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import ContactSection from "../components/contact/ContactSection"
import SEO from "../components/seo"

const Partnerships = () => {
  
return (
    <div className="partnerships">
        <Layout>
            <SEO 
                title="Web Development Partnership"
                description="Looking for a Web Development Partnership?" 
            />	          
            <section className="page-header">
                <Waypoint onEnter={() => handleWaypointIn('title-text', 'bounce-in-top')}>         
                    <div id="title-text">                 
                        <h1>Partnerships</h1> 
                    </div>    
                </Waypoint>                      
            </section> 

            <section className="content">
                <div className="gray-bg">
                    <Waypoint onEnter={() => handleWaypointIn('long-text')}>         
                        <div id="long-text">                 
                            <h2>LOOKING FOR A LONG-TERM WEB DEVELOPMENT PARTNERSHIP?</h2>

                            <p>Are you a design agency or corporate company that is looking for the following:</p>

                            <ul>
                                <li>Looking to outsource development work</li>
                                <li>Have many micro-sites for sales clients that need creating/building</li>
                            </ul>

                            <p>Then please get in touch with me to see how I can help you and discuss a preferential rate.</p>

                            <p>I'm open to building a long-term relationship with agencies or companies looking to outsource multiple websites to me.</p>
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                        </div>    
                    </Waypoint>   
                </div>

                <div className="white-bg">
                    <Waypoint onEnter={() => handleWaypointIn('build-text')}>         
                        <div id="build-text">                   
                                <h2>LOOKING FOR SOMEONE TO HELP BUILD YOUR SITE</h2>

                                <p>Do you have a web design ready but are unable or don’t have time to build it yourself? Are you looking for a seasoned web developer to help create the website for you? If you one of the following:</p>

                                <ul>
                                    <li>Freelance Web Designer</li>
                                    <li>Freelance Web Developer</li>
                                    <li>Web Design Agency</li>
                                    <li>Digital Media Agency</li>
                                    <li>SEO Agency</li>
                                    <li>Company with in-house Web Team</li>
                                    <li>Have a design but need someone to build your site?</li>
                                </ul>
                                
                                <p>Then please get in touch with me to see how I can help you.</p>
                                
                                <p>I'm happy discussing any requirements directly with the client or only going through you so the client is unaware of our involvement.		</p>	 
                                <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p> 
                            </div>    
                        </Waypoint>                                           
                </div>






          </section>

          <ContactSection/>                                
      </Layout> 
  </div>
  )
}

export default Partnerships